
import styled from 'styled-components';


export const RequirementContainer = styled.div`
  &.requirements__container{
  width: 100%;

  display: flex;

  flex-direction: column;
  align-items: flex-end;

  }
`

export const RequirementTitleContainer = styled.div`
  &.requirements__title-container{
  position: relative;

  display: flex;
  align-items: flex-flex-start;

  width: 100%;
  gap: 15px;
}
`

export const RequirementNumber = styled.h3`
  min-width: 55px;

  font-size: 45px;
  font-size: clamp(35px, 5vw, 45px);

  color: #000;
  font-weight: bold;
  text-align: left;
  text-shadow: 0 2px 2px var(--green21), 0 0 2px var(--green21), 0 0px 2px var(--green21), 0 0 2px var(--green21);


  width: auto;
  z-index: 1;
`

export const RequirementTitle = styled.h4`
display: inline-flex;
flex-wrap: wrap;
gap: 15px;
word-break: break-word;

  font-size: 35px;
  font-size: clamp(20px, 5vw, 35px);

  font-weight: bold;
  color: #fff;
  text-align: start;

  width: auto;
  z-index: 2;

  cursor: pointer;

  small{
    align-self: center;
    font-weight: normal;

    color:#7e7e7e;
    font-size: 25px;
    font-size: clamp( 15px, 5vw, 25px);

  }

  span{
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 18px;
      height: 18px;
      border: 1px solid #7e7e7e;

      padding: 2px;

      border-radius: 9px;

  }

`

export const Description = styled.p`
  font-size: 35px;
  font-size: clamp(15px, 5vw, 35px);

  font-weight: 100;
  color: #fff;
  text-align: center;

  width: 60%;

  @media (max-width: 1024px) {
    text-align: center;
  }
`

export const RequirementDescription = styled(Description)`
  &.requirements__description{
    max-height: 0;

    overflow-y: hidden;

    width: 100%;
    margin-top: 15px;
    padding-left: 70px;
    
    font-size: 20px;
    font-size: clamp(10px, 5vw, 20px);
    font-weight: 300;
    color: #fff;
    text-align: start;
    white-space: break-spaces;

    transition: height .5s ease-in-out;

    &.description-true{
        animation: slide_down 2s ease-in forwards;
    }

    > b {
        font-weight: bold;
    }

    .requirements__item{
        position: relative;

        display: flex;
        align-items: center;

        padding-left: 15px;

        text-transform: uppercase;
    
        &:before{
            content: '';
    
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
    
            width: 10px;
            height: 10px;
            border-radius: 6px;

    
            background-color: var(--green21);
        }
    }
}

`