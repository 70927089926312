import * as React from 'react';
import axios from 'axios';

import { mockup } from './utils/mockup';

import GlobalStyle from './styles/global';

import lock_image from './assets/lock_image.png';
import teamphoto from './assets/teamphoto.webp';
import line_development from './assets/line_production_3x4.png';
import line_development_vert from './assets/line_production_vert.webp';

import background_videos from './assets/background_videos.png';
import background_apps_purple from './assets/background_apps_purple.png';
import background_apps_cyan from './assets/background_apps_cyan.png';
import background_apps_light_purple from './assets/background_apps_light_purple.png';
import mockup_apps from './assets/mockup_apps.png';
import mockups_software from './assets/mockups_software.png';
import mockup_sites from './assets/mockup_sites.png';

import one_year_icon from './assets/one_year_icon.svg';
import delivery from './assets/delivery.svg';
import mentality from './assets/mentality.svg';
import projects_delivered from './assets/projects_delivered.svg';
import consolidated_company from './assets/consolidated_company.svg';
import meritocracy from './assets/meritocracy.svg';
import circle_icon from './assets/circle_icon.svg';

import iso9001 from './assets/iso9001.jpg';
import iso27001 from './assets/iso27001.png';

import selo_gptw from './assets/selo-gptw.svg';
import pegn_icon from './assets/logo-pegn.svg';

import aws_partner from './assets/aws_partner.webp';
import microsoft_partner from './assets/microsoft_partner.webp';
import scrum_alliance_partner from './assets/scrum_alliance_partner.webp';
import kanban_partner from './assets/kanban_partner.webp';
import lean_partner from './assets/lean_partner.webp';
import pbb_partner from './assets/pbb_partner.webp';

import adobe_icon from './assets/adobe_icon.svg';
import figma_icon from './assets/figma_icon.svg';
import sketch_icon from './assets/sketch_icon.svg';
import illustrator_icon from './assets/illustrator_icon.svg';
import indesign_icon from './assets/indesign_icon.svg';
import premier_icon from './assets/premier_icon.svg';
import photoshop_icon from './assets/photoshop_icon.svg';

import javascript_icon from './assets/javascript_icon.svg';
import nodejs_icon from './assets/nodejs_icon.svg';
import firebase_icon from './assets/firebase_icon.svg';
import react_native_icon from './assets/react_native_icon.svg';
import angular_icon from './assets/angular_icon.svg';
import vuejs_icon from './assets/vuejs_icon.svg';
import html_icon from './assets/html_icon.svg';
import css_icon from './assets/css_icon.svg';
import python_icon from './assets/python_icon.svg';
import java_icon from './assets/java_icon.svg';
import magento_icon from './assets/magento_icon.svg';
import dotnet_icon from './assets/dotnet.svg';
import php_icon from './assets/php.svg';
import csharp_icon from './assets/csharp.svg';
import swift_icon from './assets/swift.svg';
import flutter_icon from './assets/flutter.svg';
import objectivec_icon from './assets/objective-c.svg';
import kotlin_icon from './assets/kotlin.svg';
import sql_server_icon from './assets/sql.svg';
import oracle_icon from './assets/oracle.svg';
import html5_icon from './assets/html5.svg';
import windows_icon from './assets/windows.svg';
import macos_icon from './assets/macos.svg';
import linux_icon from './assets/linux.svg';
import c_plus_plus_icon from './assets/c++.svg';
import ruby_on_rails_icon from './assets/ruby_on_rails.svg';

import postgresql_icon from './assets/postgresql_icon.svg';
import mysql_icon from './assets/mysql_icon.svg';
import mongodb_icon from './assets/mongodb_icon.svg';

import android_icon from './assets/android_icon.svg';
import ios_icon from './assets/ios_icon.svg';

import check_icon from './assets/check_icon.svg';
import check_icon_alt from './assets/check_icon_alt.svg';

import {
  Button,
  ButtonIcon,
  ButtonText,
  Description,
  HelloFernando,
  Logo,
  Security,
  SecurityTitle,
  TextContainer,
  Title,
  SecurityTextContainer,
  SecurityDescription,
  SecurityLock,
  SecurityButton,
  WelcomeWeAreMestres,
  WelcomeWeAreMestresSurface,
  WelcomeTitle,
  WelcomeDescription,
  OurPurpose,
  OurPurposeContent,
  OurPurposeTitle,
  OurPurposeDescription,
  OurPurposeCover,
  OurPurposeOverlay,
  Techs,
  TechStack,
  TechsTitle,
  Videos,
  VideosLayer,
  VideosTitle,
  VideosContent,
  VideoItemsList,
  VideoItem,
  VideoItemContent,
  VideoItemDescription,
  VideoPlayContainer,
  ChooseMestres,
  ChooseMestresTitle,
  ChooseMestresContent,
  MotivesList,
  MotiveTitleContainer,
  MotiveTitle,
  MotiveIcon,
  MotiveDescription,
  MotiveContainer,
  Apps,
  PurpleBackground,
  AppsContent,
  AppsSubtitle,
  AppsTitle,
  AppsButton,
  AlternativeAppsSubtitle,
  AlternativeAppsTitle,
  AlternativeAppsButton,
  AppsMockup,
  CyanBackground,
  SoftwareMockup,
  LightPurpleBackground,
  SiteMockup,
  Requirements,
  RequirementsContent,
  RequirementsList,
  TimeTitle,
  BoxesContainer,
  LeftBox,
  BoxTitle,
  BoxTime,
  RightBox,
  Prices,
  PricesContent,
  PricesTitle,
  PricesAndPayments,
  PricesCards,
  PriceCard,
  PriceCardTitle,
  PriceCardValue,
  AlternativePriceCard,
  AlternativePriceCardTitle,
  AlternativePriceCardValue,
  PaymentMethods,
  PaymentMethodText,
  PaymentMethodTitle,
  AlternativePriceCardBubble,
  AlternativePriceCardBubbleText,
  TechnicalSupport,
  TechnicalSupportContent,
  TechnicalSupportDescription,
  TechnicalSupportItemsContainer,
  TechnicalSupportItems,
  TechnicalSupportItem,
  TechnicalSupportItemText,
  TechnicalSupportIcon,
  TechnicalSupportPrice,
  ContactText,
  ContactTitle,
  Contact,
  ContactButton,
  TechList,
  TechsList,
  TechItem,
  TechIcon,
  TechItemText,
  TechItemsList,
  VideoPlayer,
  ContractDetails,
  ContractContent,
  ContractTitle,
  ContractItemRow,
  ContractItemIcon,
  ContractItem,
  PaymentMethod,
  CompanyName,
  AppSitePremiun,
  GreatPlaceSection,
  RequirementsIntro,
  ContactWrapper,
  Methodology,
  MethodologyContent,
  DevelopmentLine,
  MethodologyTitle,
  QualitySection,
  CertifiesSection,
  MethodologySection,
  OurServicesSection,
  MobileDevelopmentLine,
} from './_app';
import { RequirementComponent } from './components/RequirementContainer';

type Term = 'closed-scope' | 'opened-scope';

function App() {
  const [data, setData] = React.useState<typeof mockup | undefined>();

  const [discountedValue, setDiscountValue] = React.useState(0);
  const [selectedScope, setSelectedScope] = React.useState<Term | undefined>(
    'closed-scope',
  );

  const getData = async () => {
    try {
      const id = window.location.pathname.replace('/', '');

      const response = await axios({
        method: 'GET',
        baseURL: 'https://crm.mestresdaweb.io',
        url: `/app/propostas/view/${id}.json`,
      });

      setData(response.data);

      setDiscountValue(response.data.valor_total * 0.85);
    } catch (err) {
      console.log('Atenção, ocorreu um problema!');
    }
  };

  React.useEffect(() => {
    getData();
  }, []);

  const renderRequirements = () => {
    return data?.requisitos.map((item, index) => (
      <RequirementComponent requisitos={item} index={index} key={index} />
    ));
  };

  const formatMoney = (value: number) => {
    return Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  const installmentCalc = (value: number) => {
    const entry = value * 0.3;

    const parcels = (value * 0.7) / (data?.sprints ?? 7);

    return (
      <>
        Entrada de <b>{formatMoney(entry)}</b> + <br />
        {data?.sprints ?? 7} parcelas de
        <b> {formatMoney(parcels)}</b>
      </>
    );
  };

  const creditCardCalc = (value: number) => {
    const creditCardValue = (value * 1.08) / 12;

    return (
      <>
        Juros de 8% +
        <br />
        12 parcelas de <b>{formatMoney(creditCardValue)}</b>
      </>
    );
  };

  const discountCalc = (value: number) => {
    const halfValue = value * 0.5;

    return (
      <>
        <b>{formatMoney(halfValue)}</b> no ato da
        <br /> assinatura + <b>{formatMoney(halfValue)}</b> <br />
        na entrega do projeto
      </>
    );
  };

  const handleTerm = (selectedTerm: Term) => {
    setSelectedScope(selectedTerm);
  };

  const renderPrices = () => {
    if (selectedScope === 'opened-scope') {
      return (
        <>
          <PaymentMethod>
            <PaymentMethodTitle>Parcelamento dos Mestres</PaymentMethodTitle>
            <PaymentMethodText>
              {installmentCalc(discountedValue ?? 0)}
            </PaymentMethodText>
          </PaymentMethod>
          <PaymentMethod alt>
            <PaymentMethodTitle>Cartão de Crédito</PaymentMethodTitle>
            <PaymentMethodText>
              {creditCardCalc(discountedValue ?? 0)}
            </PaymentMethodText>
          </PaymentMethod>
        </>
      );
    }

    return (
      <>
        <PaymentMethod>
          <PaymentMethodTitle>Parcelamento dos Mestres</PaymentMethodTitle>
          <PaymentMethodText>
            {installmentCalc(data?.valor_total ?? 0)}
          </PaymentMethodText>
        </PaymentMethod>
        <PaymentMethod alt>
          <PaymentMethodTitle>Cartão de Crédito</PaymentMethodTitle>
          <PaymentMethodText>
            {creditCardCalc(data?.valor_total ?? 0)}
          </PaymentMethodText>
        </PaymentMethod>
      </>
    );
  };

  const renderContract = () => {
    if (selectedScope === 'closed-scope') {
      return (
        <>
          <ContractTitle>Contrato Fechado</ContractTitle>
          <ContractItemRow>
            <ContractItemIcon
              src={check_icon}
              alt="Ícone referente aos termos dos contratos"
            />
            <ContractItem>
              A Mestres da Web se detêm aos itens descritos em contrato, ou
              seja, se houver alteração no meio do caminho, nós ponderamos se
              aquela mudança será cobrada a parte ou não.
            </ContractItem>
          </ContractItemRow>

          <ContractItemRow>
            <ContractItemIcon
              src={check_icon}
              alt="Ícone referente aos termos dos contratos"
            />
            <ContractItem>
              Além disso, acrescentamos 15% de gordura em cima das horas para
              garantir a entrega de 100% do itens contratados mesmo em caso
              imprevistos.
            </ContractItem>
          </ContractItemRow>

          <ContractItemRow>
            <ContractItemIcon
              src={check_icon}
              alt="Ícone referente aos termos dos contratos"
            />
            <ContractItem>
              Cancelando o contrato fechado tem multa.
            </ContractItem>
          </ContractItemRow>
        </>
      );
    }

    return (
      <>
        <ContractTitle alt>Contrato Aberto</ContractTitle>
        <ContractItemRow>
          <ContractItemIcon
            src={check_icon_alt}
            alt="Ícone referente aos termos dos contratos"
          />
          <ContractItem alt>
            Desconto de 15% no valor total. Não tem gordura de valor.
          </ContractItem>
        </ContractItemRow>

        <ContractItemRow>
          <ContractItemIcon
            src={check_icon_alt}
            alt="Ícone referente aos termos dos contratos"
          />
          <ContractItem alt>
            O Cliente contrata horas para o desenvolvimento e não os itens de
            contrato. Se houver alteração não existe negociação, nós fazemos
            tudo que o cliente solicitar;
          </ContractItem>
        </ContractItemRow>

        <ContractItemRow>
          <ContractItemIcon
            src={check_icon_alt}
            alt="Ícone referente aos termos dos contratos"
          />
          <ContractItem alt>
            Cliente recebe um relatório Quinzenal das horas desenvolvidas;
          </ContractItem>
        </ContractItemRow>

        <ContractItemRow>
          <ContractItemIcon
            src={check_icon_alt}
            alt="Ícone referente aos termos dos contratos"
          />
          <ContractItem alt>
            Pode cancelar o contrato com 30 dias de antecedência sem multa e nós
            entregamos todo o material e todo o desenvolvimento feito até o
            momento documentado;
          </ContractItem>
        </ContractItemRow>

        <ContractItemRow>
          <ContractItemIcon
            src={check_icon_alt}
            alt="Ícone referente aos termos dos contratos"
          />
          <ContractItem alt>
            Se levarmos menos horas do que foi contratado, essas horas ficam
            guardadas para uso do cliente em até 12 meses. Pode ser usada para
            suporte, para desenvolvimento de novas funcionalidades, configuração
            e manutenção de servidores, ou seja, todo e qualquer um de nossos
            serviços.
          </ContractItem>
        </ContractItemRow>
      </>
    );
  };

  return (
    <>
      <HelloFernando>
        <Logo />

        <TextContainer>
          <Title>Olá, {data?.cliente.nome ?? ''}</Title>
          <Description>
            Seja muito bem vindo à <CompanyName>Mestres da Web.</CompanyName>
            <br />
            Preparamos esta proposta especialmente para você!
          </Description>
          <Button href="#security">
            <ButtonText>Vamos lá</ButtonText>
            <ButtonIcon />
          </Button>
        </TextContainer>
        <Security id="security">
          <SecurityTextContainer>
            <SecurityTitle>Segurança em primeiro lugar</SecurityTitle>
            <SecurityDescription>
              Non-Disclosure-Agreements (NDA) caracteriza-se como um acordo de
              sigilo de informações. Ele tem o intuito de proteger segredos
              comerciais, e qualquer informação que não possa ser divulgada,
              mantendo assim o sigilo entre aqueles que têm acesso a ela.
            </SecurityDescription>
            <SecurityButton
              href="https://docs.google.com/forms/d/e/1FAIpQLSfpIj-UlbAQz0Zv1LusVEKJUaKpYpbPI1w8RErKoPTEcdgVRw/viewform?usp=sf_link"
              target="_blank"
            >
              Acessar NDA
            </SecurityButton>
          </SecurityTextContainer>
          <SecurityLock
            src={lock_image}
            alt="Cadeado de segurança da Mestres"
          />
        </Security>
      </HelloFernando>

      <WelcomeWeAreMestres>
        <WelcomeWeAreMestresSurface>
          <WelcomeTitle>
            Prazer, somos a<br /> Mestres da Web
          </WelcomeTitle>
          <WelcomeDescription>
            A Mestres da Web teve seu início em 2013, quando unimos mentes e
            corações movidos por uma paixão singular: a Tecnologia. Somos
            verdadeiros entusiastas da programação, do design, das inovações
            tecnológicas e de tudo o que permeia o vasto Universo Digital.
            <br />
            <br />
            Cada projeto que abraçamos é único e especial. Empregamos nossa
            vasta experiência de mais de 10 anos, investimos em extensa pesquisa
            e dedicamos nossos esforços no processo de desenvolvimento,
            esculpindo cuidadosamente cada ideia para que ela se torne uma
            Aplicação Robusta e Escalável.
          </WelcomeDescription>
        </WelcomeWeAreMestresSurface>
      </WelcomeWeAreMestres>

      <OurPurpose>
        <OurPurposeContent>
          <SecurityTextContainer>
            <OurPurposeTitle>Nosso propósito é:</OurPurposeTitle>
            <OurPurposeDescription>Valorizar pessoas.</OurPurposeDescription>
            <br />
            <br />
            <OurPurposeTitle>Nossos objetivos são</OurPurposeTitle>
            <OurPurposeDescription>
              “Tornar-se Uma das Maiores Referências em Desenvolvimento do
              Brasil”. <b>Conquistado</b>
              <br />
              <br /> “Tornar-se Uma das Maiores Referências em Desenvolvimento
              do Mundo”. <b>Em Projeção</b>
            </OurPurposeDescription>
          </SecurityTextContainer>
          <OurPurposeCover src={teamphoto} alt="Equipe competente" />
        </OurPurposeContent>
        <OurPurposeOverlay />
      </OurPurpose>

      <Methodology>
        <MethodologyContent>
          <MethodologyTitle withMargin>Mestres Line</MethodologyTitle>
          <Description>Conheça nossa linha de montagem</Description>
          <DevelopmentLine
            src={line_development}
            alt="A linha de montagem da Mestres"
          />

          <MobileDevelopmentLine
            src={line_development_vert}
            alt="A linha de montagem da Mestres"
          />
        </MethodologyContent>
      </Methodology>

      <QualitySection className="quality-section">
        <span className="quality-section__title">Padrão de qualidade ISO</span>

        <div className="quality-section__stamp-wrapper">
          <img
            className="quality-section__stamp"
            src={iso9001}
            alt="Selo ISO9001"
          />

          <img
            className="quaity-section__stamp--27001"
            src={iso27001}
            alt="Selo ISO27001"
          />
        </div>

        <a
          className="quality-section__link"
          href="https://www.iso.org"
          target="_blank"
          rel="noreferrer"
        >
          Nossos processos produtivos seguem os padrões de qualidade
          internacionais.
        </a>

        <CertifiesSection className="certifies-section">
          <div className="certifies-section__container">
            <div className="certifies-section__main">
              <div className="certifies-section__certify-wrapper light-bg secondary-certify">
                <div className="certifies-section__certify">
                  <img
                    src={aws_partner}
                    alt="Somos uma empresa desenvolvimento aplicativo"
                    width="180"
                    height="80"
                  />
                </div>
                <span className="certifies-section__legend">AWS Partner</span>
              </div>

              <div className="certifies-section__certify-wrapper light-bg secondary-certify">
                <div className="certifies-section__certify">
                  <img
                    src={microsoft_partner}
                    alt="Somos uma empresa desenvolvimento mobile"
                    width="180"
                    height="80"
                  />
                </div>
                <span className="certifies-section__legend">
                  Microsoft Parner
                </span>
              </div>

              <div className="certifies-section__certify-wrapper secondary-certify">
                <div className="certifies-section__certify">
                  <img
                    src={scrum_alliance_partner}
                    alt=" Somos uma empresa para desenvolver aplicativo membro da scrum alliance"
                    width="180"
                    height="150"
                  />
                </div>
                <span className="certifies-section__legend secondary-certify">
                  Scrum Alliance Member
                </span>
              </div>

              <div className="certifies-section__certify-wrapper secondary-certify">
                <div className="certifies-section__certify">
                  <img
                    src={kanban_partner}
                    alt="uma empresa para desenvolver app que usa o método kanban"
                    width="180"
                    height="150"
                  />
                </div>
                <span className="certifies-section__legend">Kanban</span>
              </div>

              <div className="certifies-section__certify-wrapper secondary-certify">
                <div className="certifies-section__certify">
                  <img
                    src={lean_partner}
                    alt="Fazemos parte das empresas desenvolvedoras de app que usam o método lean"
                    width="130"
                    height="110"
                  />
                </div>
                <span className="certifies-section__legend">Lean </span>
              </div>

              <div className="certifies-section__certify-wrapper secondary-certify">
                <div className="certifies-section__certify">
                  <img
                    src={pbb_partner}
                    alt="Somos empresa de desenvolvimento de aplicativos mobile parceira da pbb"
                    width="120"
                    height="120"
                  />
                </div>
                <span className="certifies-section__legend">PBB</span>
              </div>
            </div>

            <div className="certiffies-section__mobilie-certifies">
              <div className="certifies-section__mobile">
                <img
                  className="certifies-section__mobile--light"
                  src={aws_partner}
                  alt="Somos uma empresa desenvolvimento aplicativo"
                  width="90"
                  height="25"
                />
              </div>

              <div className="certifies-section__mobile">
                <img
                  className="certifies-section__mobile--light"
                  src={microsoft_partner}
                  alt="Somos uma empresa desenvolvimento mobile"
                  width="95"
                  height="55"
                />
              </div>

              <div className="certifies-section__mobile">
                <img
                  src={scrum_alliance_partner}
                  alt=" Somos uma empresa para desenvolver aplicativo membro da scrum alliance"
                  width="90"
                  height="75"
                />
              </div>

              <div className="certifies-section__mobile">
                <img
                  src={lean_partner}
                  alt="Fazemos parte das empresas desenvolvedoras de app que usam o método lean"
                  width="90"
                  height="90"
                />
              </div>

              <div className="certifies-section__mobile">
                <img
                  src={pbb_partner}
                  alt="Somos empresa de desenvolvimento de aplicativos mobile parceira da pbb"
                  width="70"
                  height="70"
                />
              </div>
              <div className="certifies-section__mobile">
                <img
                  src={kanban_partner}
                  alt="uma empresa para desenvolver app que usa o método kanban"
                  width="100"
                  height="50"
                />
              </div>
            </div>
          </div>
        </CertifiesSection>
      </QualitySection>

      <GreatPlaceSection className="great-place">
        <span className="great-place__title">
          Uma das melhores empresas para se trabalhar
        </span>

        <img
          className="great-place__stamp"
          src={selo_gptw}
          alt="Selo Great Place to Work"
        />

        <a
          className="great-place__link"
          href="https://revistapegn.globo.com/Empreendedorismo/noticia/2021/09/100-melhores-pequenas-empresas-para-trabalhar-de-2021.html"
          target="_blank"
        >
          Vencedor do Prêmio 2020 e 2021 como uma das 100 Melhores Empresas para
          se Trabalhar.
        </a>

        <a
          className="great-place__pegn"
          href="https://revistapegn.globo.com/Empreendedorismo/noticia/2021/09/100-melhores-pequenas-empresas-para-trabalhar-de-2021.html"
        >
          <img src={pegn_icon} alt="Selo Pequenas empresas grande negócio" />
        </a>
      </GreatPlaceSection>

      <Techs>
        <TechStack>
          <TechsTitle>
            #melhores
            <br className="responsive" />
            tecnologias
          </TechsTitle>
          <TechsList>
            <TechList>
              {/* <TechListTitle>Design</TechListTitle> */}
              <TechItemsList>
                <TechItem>
                  <TechIcon src={adobe_icon} />
                  <TechItemText>Adobe XD</TechItemText>
                </TechItem>
                <TechItem>
                  <TechIcon src={figma_icon} />
                  <TechItemText>Figma</TechItemText>
                </TechItem>
                <TechItem>
                  <TechIcon src={sketch_icon} />
                  <TechItemText>Sketch</TechItemText>
                </TechItem>
                <TechItem>
                  <TechIcon src={illustrator_icon} />
                  <TechItemText>Illustrator</TechItemText>
                </TechItem>
                <TechItem>
                  <TechIcon src={indesign_icon} />
                  <TechItemText>InDesign</TechItemText>
                </TechItem>
                <TechItem>
                  <TechIcon src={premier_icon} />
                  <TechItemText>Premiere</TechItemText>
                </TechItem>
                <TechItem>
                  <TechIcon src={photoshop_icon} />
                  <TechItemText>Photoshop</TechItemText>
                </TechItem>

                <TechItem>
                  <TechIcon src={javascript_icon} />
                  <TechItemText>JavaScript</TechItemText>
                </TechItem>
                <TechItem>
                  <TechIcon src={nodejs_icon} />
                  <TechItemText>Node Js</TechItemText>
                </TechItem>
                <TechItem>
                  <TechIcon src={firebase_icon} />
                  <TechItemText>Next Js</TechItemText>
                </TechItem>
                <TechItem>
                  <TechIcon src={react_native_icon} />
                  <TechItemText>React Native</TechItemText>
                </TechItem>
                <TechItem>
                  <TechIcon src={angular_icon} />
                  <TechItemText>Angular</TechItemText>
                </TechItem>
                <TechItem>
                  <TechIcon src={vuejs_icon} />
                  <TechItemText>Vue Js</TechItemText>
                </TechItem>
                <TechItem>
                  <TechIcon src={html_icon} />
                  <TechItemText>HTML 5</TechItemText>
                </TechItem>
                <TechItem>
                  <TechIcon src={css_icon} />
                  <TechItemText>CSS 3</TechItemText>
                </TechItem>
                <TechItem>
                  <TechIcon src={python_icon} />
                  <TechItemText>Python</TechItemText>
                </TechItem>

                <TechItem>
                  <TechIcon src={java_icon} />
                  <TechItemText>Java</TechItemText>
                </TechItem>

                <TechItem>
                  <TechIcon src={magento_icon} />
                  <TechItemText>Magento</TechItemText>
                </TechItem>

                <TechItem>
                  <TechIcon src={dotnet_icon} />
                  <TechItemText>.NET</TechItemText>
                </TechItem>

                <TechItem>
                  <TechIcon src={php_icon} />
                  <TechItemText>PHP</TechItemText>
                </TechItem>

                <TechItem>
                  <TechIcon src={csharp_icon} />
                  <TechItemText>C#</TechItemText>
                </TechItem>

                <TechItem>
                  <TechIcon src={c_plus_plus_icon} />
                  <TechItemText>C++</TechItemText>
                </TechItem>

                <TechItem>
                  <TechIcon src={ruby_on_rails_icon} />
                  <TechItemText>Ruby on Rails</TechItemText>
                </TechItem>

                <TechItem>
                  <TechIcon src={swift_icon} />
                  <TechItemText>Swift</TechItemText>
                </TechItem>

                <TechItem>
                  <TechIcon src={flutter_icon} />
                  <TechItemText>Flutter</TechItemText>
                </TechItem>

                <TechItem>
                  <TechIcon src={objectivec_icon} />
                  <TechItemText>Objective-C</TechItemText>
                </TechItem>

                <TechItem>
                  <TechIcon src={kotlin_icon} />
                  <TechItemText>Kotlin</TechItemText>
                </TechItem>

                <TechItem>
                  <TechIcon src={postgresql_icon} />
                  <TechItemText>PostgreSQL</TechItemText>
                </TechItem>

                <TechItem>
                  <TechIcon src={mysql_icon} />
                  <TechItemText>MySQL</TechItemText>
                </TechItem>
                <TechItem>
                  <TechIcon src={mongodb_icon} />
                  <TechItemText>MongoDB</TechItemText>
                </TechItem>

                <TechItem>
                  <TechIcon src={oracle_icon} />
                  <TechItemText>Oracle</TechItemText>
                </TechItem>

                <TechItem>
                  <TechIcon src={sql_server_icon} />
                  <TechItemText>SQL Server</TechItemText>
                </TechItem>

                <TechItem>
                  <TechIcon src={android_icon} />
                  <TechItemText>Android</TechItemText>
                </TechItem>

                <TechItem>
                  <TechIcon src={ios_icon} />
                  <TechItemText>iOS</TechItemText>
                </TechItem>

                <TechItem>
                  <TechIcon src={html5_icon} />
                  <TechItemText>Web</TechItemText>
                </TechItem>

                <TechItem>
                  <TechIcon src={windows_icon} />
                  <TechItemText>Windows</TechItemText>
                </TechItem>

                <TechItem>
                  <TechIcon src={macos_icon} />
                  <TechItemText>MacOs</TechItemText>
                </TechItem>

                <TechItem>
                  <TechIcon src={linux_icon} />
                  <TechItemText>Linux</TechItemText>
                </TechItem>
              </TechItemsList>
            </TechList>
          </TechsList>
        </TechStack>
      </Techs>

      <Videos>
        <VideosLayer
          src={background_videos}
          alt="Layer de background dos vídeos"
        />
        <VideosContent>
          <VideosTitle>
            Um vídeo vale mais
            <br />
            que mil palavras
          </VideosTitle>
          <VideoItemsList>
            <VideoItem>
              <VideoPlayContainer>
                <VideoPlayer url="https://www.youtube.com/watch?v=GFMcZh6rjgM" />
              </VideoPlayContainer>
              <VideoItemContent>
                <VideoItemDescription>
                  Depoimento da nossa cliente Priscila, influenciadora renomada
                  e dona da Yoga Co . Desenvolvemos um app e uma plataforma
                  exclusiva de videos e interação! Muito obrigado, Priscila!
                </VideoItemDescription>
              </VideoItemContent>
            </VideoItem>

            <VideoItem>
              <VideoPlayContainer>
                <VideoPlayer url="https://www.youtube.com/watch?v=blRBbA11zWQ" />
              </VideoPlayContainer>
              <VideoItemContent>
                <VideoItemDescription>
                  Depoimento de nosso cliente William CEO da RepFy. Para este
                  projeto desenvolvemos aplicativos que criam uma ligação entre
                  clientes e prestadores de serviços ! Muito obrigado, William!
                </VideoItemDescription>
              </VideoItemContent>
            </VideoItem>

            <VideoItem>
              <VideoPlayContainer>
                <VideoPlayer url="https://www.youtube.com/watch?v=_xWdQ8Wq5RY&t=4s" />
              </VideoPlayContainer>
              <VideoItemContent>
                <VideoItemDescription>
                  Depoimento da nossa cliente Andréia da Ingresso Now, a
                  plataforma mais completa do mercado, que oferece serviços para
                  eventos corporativos, festas, shows, teatros e qualquer tipo
                  de evento. Muito obrigada, Andréia!
                </VideoItemDescription>
              </VideoItemContent>
            </VideoItem>
          </VideoItemsList>
        </VideosContent>
      </Videos>

      <MethodologySection className="methodology-section">
        <div className="methodology-section__container">
          <div className="methodology-section__card">
            <div className="methodology-section__title-wrapper">
              <h2 className="methodology-section__title">Nossa metodologia</h2>
            </div>

            <div className="methodology-section__line-wrapper">
              {/* <div className="methodology-section__line-container">
                <img
                  src={mquickly}
                  alt="Mquicly "
                  width={1185}
                  height={345}
                />
              </div> */}
            </div>
          </div>
        </div>
      </MethodologySection>

      <ChooseMestres>
        <ChooseMestresContent>
          <ChooseMestresTitle>Escolha a Mestres da Web</ChooseMestresTitle>
          <MotivesList>
            <MotiveContainer>
              <MotiveTitleContainer>
                <MotiveIcon src={one_year_icon} />
                <MotiveTitle>Um ano de garantia</MotiveTitle>
              </MotiveTitleContainer>
              <MotiveDescription>
                Somos a única fábrica de softwares e aplicativos que fornece
                essa garantia. Fazemos isso para que você possa ficar tranquilo
                e assegurar que seu investimento está bem empregado e em boas
                mãos.
              </MotiveDescription>
            </MotiveContainer>
            <MotiveContainer>
              <MotiveTitleContainer>
                <MotiveIcon src={delivery} />
                <MotiveTitle>Entregas por módulos</MotiveTitle>
              </MotiveTitleContainer>
              <MotiveDescription>
                Esperar todo o prazo de entrega para você testar seu projeto é
                um tiro no pé de ambos os lados, e por isso, fazemos entregas
                por módulos, para que ao final do seu projeto ele esteja
                realmente finalizado.
              </MotiveDescription>
            </MotiveContainer>
            <MotiveContainer>
              <MotiveTitleContainer>
                <MotiveIcon src={mentality} />
                <MotiveTitle>Mentalidade consultiva</MotiveTitle>
              </MotiveTitleContainer>
              <MotiveDescription>
                Nós não ficamos contentes só de desenvolver seu projeto, nós
                precisamos entregar o melhor para você, e para isso, nós usamos
                toda nossa experiência para propor melhorias na sua ideia e
                projeto.
              </MotiveDescription>
            </MotiveContainer>
          </MotivesList>
          <MotivesList>
            <MotiveContainer>
              <MotiveTitleContainer>
                <MotiveIcon src={projects_delivered} />
                <MotiveTitle>+ de 350 projetos entregues</MotiveTitle>
              </MotiveTitleContainer>
              <MotiveDescription>
                É com muito orgulho e carinho que produzimos mais de 350
                projetos digitais, como portais, sites, sistemas web,
                aplicativos e manutenção / melhoria em projetos de código
                legado.
              </MotiveDescription>
            </MotiveContainer>
            <MotiveContainer>
              <MotiveTitleContainer>
                <MotiveIcon src={consolidated_company} />
                <MotiveTitle>Empresa consolidada</MotiveTitle>
              </MotiveTitleContainer>
              <MotiveDescription>
                Não somos aventureiros, já estamos há mais de 9 anos no mercado,
                em constante crescimento. Fazem parte do nosso time até mesmo
                Mestres Universitários e Professores de Cursos Renomados.
              </MotiveDescription>
            </MotiveContainer>
            <MotiveContainer>
              <MotiveTitleContainer>
                <MotiveIcon src={meritocracy} />
                <MotiveTitle>Gestão de meritocracia</MotiveTitle>
              </MotiveTitleContainer>
              <MotiveDescription>
                Toda equipe de desenvolvimento tem uma renda variável ligada as
                entregas dos projetos, como: Design, Experiência do usuário,
                qualidade do código, prazo de entrega, consultoria e até mesmo
                avaliação final do cliente.
              </MotiveDescription>
            </MotiveContainer>
          </MotivesList>
        </ChooseMestresContent>
      </ChooseMestres>

      <OurServicesSection className="ourservices-section">
        <div className="ourservices-section__container">
          <div className="ourservices-section__title-wrapper">
            <h2 className="ourservices-section__title">
              Nossos <b> serviços</b>
            </h2>

            <p className="ourservices-section__text">
              Conheça os nossos serviços e fique por dentro das atividades que
              realizamos!
            </p>

            <div className="ourservices-section__earth-decoration" />
          </div>

          <div className="ourservices-section__services-wrapper">
            <div className="ourservices-section__service service-01">
              <span>Desenvolvimento de Aplicativo</span>
            </div>

            <div className="ourservices-section__service service-02">
              <span>Outsourcing de profissionais</span>
            </div>

            <div className="ourservices-section__service service-03">
              <span>Segurança da informação</span>
            </div>

            <div className="ourservices-section__service service-04">
              <span>Desenvolvimento de Software Web/Desktop</span>
            </div>

            <div className="ourservices-section__service service-05">
              <span>Adequação LGPD</span>
            </div>

            <div className="ourservices-section__service service-06">
              <span>Consultoria de TI</span>
            </div>

            <div className="ourservices-section__service service-07">
              <span>Desenvolvimento de Software Embarcado</span>
            </div>

            <div className="ourservices-section__service service-08">
              <span>Desenvolvimento de Produtos IOT</span>
            </div>

            <div className="ourservices-section__service service-09">
              <span>Serviço de Hospedagem</span>
            </div>

            <div className="ourservices-section__service service-10">
              <span>Product Discovery</span>
              <small>Da concepção da ideia ao Design de interfaces</small>
            </div>

            <div className="ourservices-section__service service-11">
              <span>Inteligência Artificial</span>
            </div>
          </div>
        </div>
      </OurServicesSection>

      <Apps>
        <PurpleBackground src={background_apps_purple} />
        <CyanBackground src={background_apps_cyan} />
        <LightPurpleBackground src={background_apps_light_purple} />
        <AppsContent>
          <AppsSubtitle>apps</AppsSubtitle>
          <AppsTitle>
            Design,
            <br /> usabilidade e<br /> Inteligência.
          </AppsTitle>
          <AppsButton
            href="https://www.youtube.com/watch?v=GFMcZh6rjgM"
            target="_blank"
          >
            Ver portfólio
          </AppsButton>
        </AppsContent>
        <AppsMockup src={mockup_apps} />
        <AppsContent>
          <AlternativeAppsSubtitle>softwares</AlternativeAppsSubtitle>
          <AlternativeAppsTitle>
            Design,
            <br /> usabilidade e<br /> Inteligência.
          </AlternativeAppsTitle>
          <AlternativeAppsButton
            href="https://www.behance.net/mestresdaweb?tracking_source=search_users_recommended%7Cmestres%20da%20web"
            target="_blank"
          >
            Ver portfólio
          </AlternativeAppsButton>
        </AppsContent>
        <SoftwareMockup src={mockups_software} />
        <AppsContent>
          <AppSitePremiun>sites premium</AppSitePremiun>
          <AppsTitle>
            Design,
            <br /> usabilidade e<br /> Inteligência.
          </AppsTitle>
          <AppsButton
            href="https://www.behance.net/mestresdaweb?tracking_source=search_users_recommended%7Cmestres%20da%20web"
            target="_blank"
          >
            Ver portfólio
          </AppsButton>
        </AppsContent>
        <SiteMockup src={mockup_sites} />
      </Apps>

      <Requirements>
        <RequirementsContent>
          <Title>Requisitos</Title>
          <RequirementsIntro className="requirements-intro">
            O seu projeto abrange <b>{data?.requisitos.length}</b> requisitos
            básicos, são eles:
          </RequirementsIntro>
          <RequirementsList>{renderRequirements()}</RequirementsList>

          <TimeTitle>Prazo</TimeTitle>
          <BoxesContainer>
            <LeftBox
              selected={selectedScope === 'closed-scope'}
              onClick={() => handleTerm('closed-scope')}
            >
              <BoxTitle>Contrato Fechado</BoxTitle>
              <BoxTime>{data?.dias_uteis} dias úteis</BoxTime>
            </LeftBox>
            <RightBox
              selected={selectedScope === 'opened-scope'}
              onClick={() => handleTerm('opened-scope')}
            >
              <BoxTitle>Contrato Aberto</BoxTitle>
              <BoxTime>
                {data?.sprints &&
                  `${data?.sprints * 2} ${
                    Number(data?.sprints * 2) > 1 ? 'sprints' : 'sprint'
                  }`}
              </BoxTime>
            </RightBox>
          </BoxesContainer>
        </RequirementsContent>
      </Requirements>

      <Prices>
        <PricesContent>
          <PricesTitle>Investimento total</PricesTitle>
          <PricesAndPayments>
            <PricesCards>
              <PriceCard
                selected={selectedScope === 'closed-scope'}
                onClick={() => handleTerm('closed-scope')}
              >
                <PriceCardTitle>Contrato Fechado</PriceCardTitle>
                <PriceCardValue>
                  {formatMoney(data?.valor_total ?? 0)}
                </PriceCardValue>
              </PriceCard>
              <AlternativePriceCard
                selected={selectedScope === 'opened-scope'}
                onClick={() => handleTerm('opened-scope')}
              >
                <AlternativePriceCardTitle
                  selected={selectedScope === 'opened-scope'}
                >
                  Contrato Aberto
                </AlternativePriceCardTitle>
                <AlternativePriceCardValue
                  selected={selectedScope === 'opened-scope'}
                >
                  {formatMoney(discountedValue ?? 0)}
                </AlternativePriceCardValue>
                <AlternativePriceCardBubble
                  selected={selectedScope === 'opened-scope'}
                >
                  <AlternativePriceCardBubbleText
                    selected={selectedScope === 'opened-scope'}
                  >
                    15%
                    <br />
                    off
                  </AlternativePriceCardBubbleText>
                </AlternativePriceCardBubble>
              </AlternativePriceCard>
            </PricesCards>
          </PricesAndPayments>
        </PricesContent>

        <ContractDetails alt={selectedScope === 'opened-scope'}>
          <ContractContent>{renderContract()}</ContractContent>
        </ContractDetails>
      </Prices>

      <PaymentMethods>{renderPrices()}</PaymentMethods>

      <TechnicalSupport>
        <TechnicalSupportContent>
          <Title>Suporte técnico</Title>
          <TechnicalSupportDescription>
            O suporte descrito abaixo
            <b> não inclui problemas de desenvolvimento</b>, pois para isso
            oferecemos 1 ano de garantia.
          </TechnicalSupportDescription>
          <TechnicalSupportItemsContainer>
            <TechnicalSupportItems>
              <TechnicalSupportItem>
                <TechnicalSupportIcon src={circle_icon} />
                <TechnicalSupportItemText>
                  Testes automatizados/humanizados
                </TechnicalSupportItemText>
              </TechnicalSupportItem>
              <TechnicalSupportItem>
                <TechnicalSupportIcon src={circle_icon} />
                <TechnicalSupportItemText>
                  Suporte de problemas/ocorrências de qualquer tipo, na
                  plataforma.
                </TechnicalSupportItemText>
              </TechnicalSupportItem>
              <TechnicalSupportItem>
                <TechnicalSupportIcon src={circle_icon} />
                <TechnicalSupportItemText>
                  Manutenção e atualização do servidor (Dedicado, cloud e ou
                  vps).
                </TechnicalSupportItemText>
              </TechnicalSupportItem>
              <TechnicalSupportItem>
                <TechnicalSupportIcon src={circle_icon} />
                <TechnicalSupportItemText>
                  Manutenção e suporte dos códigos back-end e front-end.
                </TechnicalSupportItemText>
              </TechnicalSupportItem>
            </TechnicalSupportItems>
            <TechnicalSupportItems>
              <TechnicalSupportItem>
                <TechnicalSupportIcon src={circle_icon} />
                <TechnicalSupportItemText>
                  Ajustes e novas implementações no código.
                </TechnicalSupportItemText>
              </TechnicalSupportItem>
              <TechnicalSupportItem>
                <TechnicalSupportIcon src={circle_icon} />
                <TechnicalSupportItemText>
                  Treinamento de uso para novos colaboradores.
                </TechnicalSupportItemText>
              </TechnicalSupportItem>
              <TechnicalSupportItem>
                <TechnicalSupportIcon src={circle_icon} />
                <TechnicalSupportItemText>
                  Atendimento para dúvidas técnicas.
                </TechnicalSupportItemText>
              </TechnicalSupportItem>
              <TechnicalSupportItem>
                <TechnicalSupportIcon src={circle_icon} />
                <TechnicalSupportItemText>
                  Horas não utilizadas serão acumuladas por até 90 dias da
                  renovação
                </TechnicalSupportItemText>
              </TechnicalSupportItem>
            </TechnicalSupportItems>
          </TechnicalSupportItemsContainer>
          <TechnicalSupportPrice>
            {data?.suporte_horas} horas mensais:&nbsp;
            <b> {formatMoney(119.9 * (data?.suporte_horas ?? 0))}</b>
          </TechnicalSupportPrice>
        </TechnicalSupportContent>

        <ContactWrapper className="contact-section">
          <VideoPlayContainer className="contact__video-wrapper">
            <VideoPlayer
              className="contact__video"
              url="https://www.youtube.com/watch?v=hBYWExVoEB4"
            />
          </VideoPlayContainer>
          <Contact>
            <Logo />
            <ContactText>
              MW TECNOLOGIA E PROGRAMAÇÃO CNPJ: 32.074.620-0001/61
            </ContactText>
            <ContactTitle>Dê o primeiro passo para o sucesso!</ContactTitle>
            <ContactButton
              href="https://docs.google.com/forms/d/e/1FAIpQLSdpa0SYdBuhqdKKpWODgqvmdo1XgyXfQEp-kaxuE6nHo8q6HA/viewform?usp=sf_link"
              target="_blank"
            >
              Dados para o contrato
            </ContactButton>
            {/* <ContactSubText>Preciso pensar...</ContactSubText> */}
          </Contact>
        </ContactWrapper>
      </TechnicalSupport>
      <GlobalStyle />
    </>
  );
}

export default App;
