import styled from 'styled-components'
import ReactPlayer from 'react-player'

import background from './assets/background_page.png'
import mosaico_mestre from './assets/mosaico_mestre.png'
import background_surface from './assets/background_surface.png'
import logo from './assets/logo-ciano.png'
import arrow_down_circle from './assets/arrow_down_circle.png'
import background_rounded_button from './assets/background_rounded_button.png'
import background_rounded_white_button from './assets/background_rounded_white_button.png'
import background_rounded_purple_button from './assets/background_rounded_purple_button.png'
import methodology from './assets/methodology.png'

import mquickly_logo from './assets/mquickly_logo.png'
import mquickly from './assets/mquickly.png'


import service_phone_icon from './assets/service_phone_icon.webp'
import service_person_icon from './assets/service_person_icon.webp'
import service_checked_icon from './assets/service_checked_icon.webp'
import service_laptop_icon from './assets/service_laptop_icon.webp'
import service_shield_icon from './assets/service_shield_icon.webp'
import service_tools_icon from './assets/service_tools_icon.webp'
import service_cicle_icon from './assets/service_cicle_icon.webp'
import service_bag_icon from './assets/service_bag_icon.webp'
import service_squares_icon from './assets/service_squares_icon.webp'
import service_sidebar_icon from './assets/service_sidebar_icon.webp'
import service_int_icon from './assets/service_int_icon.webp'


export const HelloFernando = styled.div`
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  background-image: url(${background});

  background-color: #000000;

  display: flex;
  flex-direction: column;
`

export const Logo = styled.div`
  width: 270px;
  height: 152px;

  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${logo});
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  height: 60vh;
`

export const Title = styled.h3`
  font-size: 55px;
  font-size: clamp(30px, 5vw, 55px);

  font-weight: bold;
  color: #21ffb5;

  @media (max-width: 1024px) {
    text-align: center;
  }
`

export const Description = styled.p`
  font-size: 35px;
  font-size: clamp(15px, 5vw, 35px);

  font-weight: 100;
  color: #fff;
  text-align: center;

  width: 60%;

  @media (max-width: 1024px) {
    text-align: center;
  }
`

export const CompanyName = styled.b`
  font-weight: bold;

  @media (max-width: 1201px) {
    display: block;
  }
`

export const Button = styled.a`
  background: unset;
  border: unset;
  cursor: pointer;

  text-decoration: none;

  font-family: 'Montserrat';

  display: flex;
  align-items: center;
`

export const ButtonText = styled.p`
  font-size: 20px;
  color: #21ffb5;
`

export const ButtonIcon = styled.div`
  width: 70px;
  height: 70px;

  margin-left: 10px;

  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${arrow_down_circle});
`

export const Security = styled.div`
  display: flex;

  justify-content: space-between;

  max-width: 1280px;
  width: 100%;
  margin: 0 auto;

  margin-top: 200px;

  flex-wrap: wrap;
  gap: 50px;

  @media (max-width: 1340px) {
    flex-direction: column-reverse;

    align-items: center;
  }
`

export const SecurityTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  max-width: 500px;

  @media (max-width: 1340px) {
    text-align: center;
  }

  @media (max-width: 1024px) {
    align-items: center;

    padding: 0 20px;
  }
`

export const SecurityTitle = styled(Title)``

export const SecurityDescription = styled(Description)`
  font-size: 25px;
  font-weight: 300;

  width: 100%;
  margin-top: 55px;

  text-align: start;

  @media (max-width: 1340px) {
    text-align: center;
  }
`

export const SecurityButton = styled.a`
  background: unset;
  border: unset;
  cursor: pointer;

  text-decoration: none;

  font-family: 'Montserrat';

  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url(${background_rounded_button});

  max-width: 269px;
  width: 70%;
  height: 65px;
  margin-top: 30px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #21ffb5;
  font-size: 20px;
  font-weight: 300;

  @media (max-width: 1340px) {
    margin: 30px auto 0 auto;
  }
`

export const SecurityLock = styled.img`
  width: 100%;
  max-width: 547px;
  height: min-content;

  @media (max-width: 1340px) {
    max-width: 347px;
  }
`

export const WelcomeWeAreMestres = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${mosaico_mestre});

  display: flex;
  flex-direction: column;
`

export const WelcomeWeAreMestresSurface = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${background_surface});

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 50px 0;

  @media (min-width: 1024.1px) {
    height: 100vh;
  }
`

export const WelcomeTitle = styled(Title)`
  text-align: center;
`

export const WelcomeDescription = styled(Description)`
  margin-top: 70px;
`

export const OurPurpose = styled.div`
  background-color: #101010;

  position: relative;
`

export const OurPurposeContent = styled.div`
  position: relative;
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;

  flex-wrap: wrap;
  gap: 50px;

  padding: 50px 20px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1189px) {
    justify-content: center;
  }
`

export const OurPurposeTitle = styled(Title)`
  font-size: 45px;
  font-size: clamp(25px, 5vw, 45px);
  color: #fff;
  text-align: start;

  @media (max-width: 1189px) {
    text-align: center;
  }
`

export const OurPurposeDescription = styled(Description)`
  font-size: 25px;
  font-size: clamp(10px, 5vw, 25px);
  color: #fff;
  text-align: start;
  margin-top: 15px;
  width: 100%;

  @media (max-width: 1189px) {
    text-align: center;
  }
`

export const OurPurposeCover = styled.img`
  width: 100%;
  max-width: 600px;
  height: min-content;
`

export const OurPurposeOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 60%,
    rgba(0, 0, 0, 0.98)
  );
`

export const Methodology = styled.div`
  background: #000;
  padding: 3em 0;

  @media (min-width: 568px){
    padding-bottom: 7em;
  }
`

export const MethodologyContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 1280px;
  width: 100%;
  margin: 0 auto;

  flex-wrap: wrap;
  gap: 50px;

  @media (max-width: 1024px) {
    padding: 0 20px;
  }
`

export const MethodologyTitle = styled(Title)`
  color: #fff;
  text-align: center;

  margin-top: ${({ withMargin }: { withMargin?: boolean }) =>
    withMargin ? '100px' : '0px'};
`

export const MethodologyCover = styled.div`
  width: 1280px;
  height: 600px;
  margin-top: 100px;

  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${methodology});
`

export const DevelopmentLine = styled.img`
  width: 100%;
  max-width: 600px;
  height: min-content;

  margin-top: 15px;

  @media (max-width:568px){
    display: none;
  }
`

export const MobileDevelopmentLine = styled.img`
  width: 100%;
  max-width: 600px;
  height: min-content;

  margin-top: 15px;

    @media (min-width:568px){
    display: none;
  }
`



export const QualitySection = styled.section`
  &.quality-section{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2em;

    padding: 2em;

    .quality-section__title{
      color: #21ffb5;
      font-size: 55px;
      font-size: clamp(30px, 5vw, 55px);
      font-weight: bold;
      text-align: center;
    }

    .quality-section__stamp-wrapper{
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      gap: 30px;

      width: 100%;
      max-width: 1200px;
    }

    .quality-section__stamp {
      width: 100%;
      max-width: 400px;
      height: fit-content;

      border-radius: 35px;
    }
    
    .quaity-section__stamp--27001{
      width: 100%;
      max-width: 470px;
      height: auto;
      border-radius: 0px;

    }

    .quality-section__link{
      max-width: 500px;

      color: #fff;
      text-decoration: none;
      font-size: 30px;
      font-size: clamp(12px, 5vw, 30px);
      text-align: center;
    }

    .quality-section__pegn{
      img{
        width: 250px;
      }
    }
}
`;

export const CertifiesSection = styled.div`
  &.certifies-section {
    padding:  15px;

    @media (max-width: 768px) {
      padding: 1em 0;
    }

    &:hover {
      .certifies-section__earth-decoration {
        transform: scale(0.7) rotateZ(-20deg);
      }
    }

    .certifies-section__container {
      display: flex;
      flex-direction: column;

      width: 100%;
      max-width: 800px;

      margin: 0 auto;
    }

    .certifies-section__wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      width: 100%;

      padding: 0 2em;
    }


    .certifies-section__main {
      display: flex;
      justify-content: space-evenly;
      gap: 50px;
      flex-wrap: wrap;
    }

    .certifies-section__certify-wrapper {
      position: relative;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 30px;

      width: 100%;
      max-width: 180px;
      height: 250px;

      text-indent: -99999px;

      border-radius: 30px;

      border-top: 2px solid var(--gray);
      border-bottom: 2px solid var(--gray);

      transition: all 0.5s ease-out;

      &.light-bg {
        img {
          background-color: #fff;
          object-fit: contain;
        }
      }

      img {
        border-radius: 10px;
      }

      &:hover {
        transform: translateY(-20px);

        border-top: 2px solid var(--purple);
        border-bottom: 2px solid var(--purple);
      }

      @media (max-width: 568px) {
        &.secondary-certify {
          display: none;
        }
      }
    }

    .certifies-section__certify-light {
      background-color: #fff;
    }

    .certifies-section__certify {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      width: 100%;

      max-width: 150px;
      height: 230px;

      text-indent: -99999px;

      border-radius: 30px;
      border-bottom: 2px solid var(--purple);
    }

    .certifies-section__legend {
      color: var(--gray);
      font-size: 1.25rem;
      text-align: center;
    }

    .certiffies-section__mobilie-certifies {
      display: flex;
      gap: 15px;
      flex-wrap: wrap;
      justify-content: space-evenly;

      width: 95%;

      margin: 0 auto;

      .certifies-section__mobile--light {
        background-color: rgb(255 255 255 / 90%);
        backdrop-filter: blur(5px);
      }

      @media (min-width: 568px) {
        display: none;
      }
    }

    .certifies-section__mobile {
      width: 100px;
      height: 100px;

      display: flex;

      padding: 10px;

      border-radius: 15px;
      border-bottom: 2px solid var(--purple);
      border-top: 2px solid var(--purple);

      img {
        border-radius: 10px;
        object-fit: contain;
      }
    }

    @media (max-width: 768px) {
      padding: 2em 0;
    }
  }
`;

export const GreatPlaceSection = styled.section`
  &.great-place{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2em;

    padding: 2em;

    .great-place__title{
      width: 100%;
      max-width: 800px;
      color: #21ffb5;
      font-size: 55px;
      font-size: clamp(30px, 5vw, 55px);

      font-weight: bold;
      text-align: center;
    }

    .great-place__stamp {
      width: 210px;

      padding: 15px 0;
    }

    .great-place__link{
      max-width: 500px;

      color: #fff;
      text-decoration: none;
      font-size: 30px;
      font-size: clamp(12px, 5vw, 30px);
      text-align: center;
    }

    .great-place__pegn{
      img{
        width: 250px;
      }
    }
}
`;

export const Techs = styled.div`
  background: #000;
  padding-top: 5em;
`

export const TechsTitle = styled(Title)`
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
`

export const TechStack = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const TechsList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  flex-wrap: wrap;
  gap: 60px;

  @media (max-width: 1199px) {
    flex-direction: column;
  }

  @media (max-width: 1024px) {
    justify-content: center;
  }
`

export const TechList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 1em;

  @media (max-width: 1199px) {
    margin-top: 0px;
  }
`

export const TechItemsList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 100%;

  margin-top: 30px;

  flex-wrap: wrap;
  gap: 60px;
`


export const TechItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const TechIcon = styled.img`
  max-height: 60px;
  height: 100%;
  width: auto;
`

export const TechItemText = styled.p`
  font-size: 25px;
  font-size: clamp(15px, 5vw, 25px);

  margin-top: 15px;

  font-weight: bold;
  color: #707070;
  text-align: center;
`

export const Videos = styled.div`
  position: relative;

  background: #000;

  padding: 100px 0;

  margin-top: 100px;

  iframe{
    pointer-events: all !important;
  }
`

export const VideosLayer = styled.img`
  width: 80%;
  height: min-content;

  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;

  @media (max-width: 560px) {
    width: 100%;
  }
`

export const VideosContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  max-width: 80vw;
  width: 100%;
  margin: 0 auto;

  flex-wrap: wrap;
  gap: 50px;

  @media (max-width: 1024px) {
    padding: 0 20px;
  }
`

export const VideosTitle = styled(Title)`
  text-align: right;
  color: #fff;
  z-index: 1;

  @media (max-width: 560px) {
    text-align: center;
    margin: 0 auto;
  }

  @media (max-width: 430px) {
    margin-top: -30px;
  }
`

export const VideoItemsList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 40px;

  margin-top: 30px;
  z-index: 1;
`

export const VideoItem = styled.div`
  max-width: 328px;
  width: 100%;
  height: 415px;

  border: unset;
  border-radius: 24px;
  background: #101010;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`

export const VideoPlayer = styled(ReactPlayer).attrs({
  width: '100%',
  height: '100%',
})`
  border-radius: 24px;
`

export const VideoItemContent = styled.div`
  position: relative;
  width: 100%;
  height: 50%;

  border: unset;
  border-radius: 24px;

  background: #2d2e2e;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const VideoItemDescription = styled.p`
  font-size: 20px;
  font-size: clamp(10px, 5vw, 20px);

  font-weight: 300;
  color: #fff;
  text-align: center;

  width: 80%;

  > b {
    font-weight: bold;
  }

  @media (max-width: 1024px) {
    text-align: center;
  }
`

export const VideoPlayContainer = styled.div`
  display: flex;
  flex: 1;

  width: 100%;

  border-top-right-radius: 24px;
  border-top-left-radius: 24px;

  border: unset;
  overflow: hidden;

  align-items: center;
  justify-content: center;
`
export const ChooseMestres = styled.div`
  position: relative;

  background: #000;
  padding-top: 200px;

  padding: 100px 0;
`

export const ChooseMestresContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 1280px;
  width: 100%;
  margin: 0 auto;

  flex-wrap: wrap;
  gap: 50px;

  @media (max-width: 1024px) {
    padding: 0 20px;
  }
`

export const ChooseMestresTitle = styled(Title)`
  text-align: center;
`

export const MotivesList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  flex-wrap: wrap;
  gap: 40px;
`

export const MotiveContainer = styled.div`
  max-width: 395px;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`

export const MotiveTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`

export const MotiveIcon = styled.img`
  max-width: 159px;
  width: 100%;
  height: min-content;
`

export const MotiveTitle = styled.h4`

  font-size: 35px;
  font-size: clamp(20px, 5vw, 35px);

  font-weight: bold;
  color: #fff;
  text-align: start;

  width: auto;
`

export const MotiveDescription = styled(Description)`
  font-size: 20px;
  font-size: clamp(10px, 5vw, 20px);

  font-weight: 300;
  color: #fff;
  text-align: start;

  width: 100%;

  > b {
    font-weight: bold;
  }

  @media (max-width: 1264px) {
    text-align: center;
  }
`

export const MethodologySection = styled.section`
  &.methodology-section {
margin-top: 9em;

    @media (max-width: 768px) {
      padding: 2em 0;
    }
    .methodology-section__container {
      width: 100%;
      max-width: 1300px;
      margin: 0 auto;
      padding: 15px;
    }
    .methodology-section__card {
      position: relative;
      display: flex;
      gap: 15px;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      max-width: 1300px;

      margin: 0 auto;
      padding: 2.5rem;
      background-color: #000;
      border-radius: 50px;

      &:before {
        content: '';
        position: relative;
        z-index: 0;
        left: 50%;
        transform: translate(-50%, 0);

        width: 50%;
        height: 250px;

        margin-top: -10%;

        text-indent: -99999px;
        background-image: url(${mquickly_logo});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        transition: all 0.5s ease-out;
      }
      &:hover {
        &:before {
          transform: translate(-50%, -5%);
        }
      }
    }
    .methodology-section__title-wrapper {
      display: flex;
      flex-direction: column;
      gap: 15px;

      margin: 0 auto;
    }
    .methodology-section__title {
      font-size: 2.5rem;
        color: #24ffb5 !important;
    }
    .methodology-section__text {
      b {
        color: #24ffb5;
      }
    }
    .methodology-section__line-wrapper {
      width: 100%;
      min-height: 350px;
      overflow-x: auto;
      display: flex;
      align-items: center;

      background-image: url(${mquickly});
      background-size: contain;
      background-repeat: no-repeat;
    }

  }
`;

export const OurServicesSection = styled.section`
  &.ourservices-section {
    padding: 5em 0;

    width: 100%;
    color:  #24ffb5 ; 
    

    @media (max-width: 768px) {
      padding: 2em 0;
    }
    &:hover {
      .ourservices-section__earth-decoration {
        transform: scale(0.7) rotateZ(-20deg);
      }
    }
    .ourservices-section__container {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
      padding: 15px;
    }
    .ourservices-section__title-wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 15px;
      width: 100%;
      padding: 0 15px 2em;
    }
    .ourservices-section__title {
      display: flex;
      flex-direction: column;
      gap: 5px;
      z-index: 1;
      max-width: 800px;
      font-size: 3rem;

      margin: 0 auto;

      width: 100%;
      color: #21ffb5;
      font-size: 55px;
      font-size: clamp(30px,5vw,55px);
      font-weight: bold;
      text-align: center;

      b {
        color: var(--green);
      }
    }
    .ourservices-section__text {
      width: 100%;
      max-width: 370px;
      margin: 0 auto;
      text-align: center;
      
      color: #fff;
    }
    .ourservices-section__services-wrapper {
      display: flex;
      justify-content: flex-start;
      gap: 30px 150px;
      flex-wrap: wrap;
      width: 100%;
      max-width: 840px;
      margin: 0 auto;
      @media (max-width: 768px) {
        gap: 20px 30px;
        padding: 15px;
      }
    }
    .ourservices-section__service {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 15px;
      transition: all 0.5s ease-out;
      span {
        width: 178px;
        color: #fff;
      }
      small {
        width: 100%;
        display: inline-flex;
        flex-wrap: wrap;

        width: 150px;
        margin-top: -5px;
        
        font-size: 0.8rem;
        color: #fff;

      }
      &:before {
        content: '';
        width: 33px;
        height: 33px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
          filter: hue-rotate(253deg) brightness(2.2);

      }
      &:hover {
        transform: translateY(-10px);
      }
      &.service-01 {
        &:before {
          background-image: url(${service_phone_icon});
        }
      }
      &.service-02 {
        &:before {
          background-image: url(${service_person_icon});
        }
      }
      &.service-03 {
        &:before {
          background-image: url(${service_checked_icon});
        }
      }
      &.service-04 {
        &:before {
          background-image: url(${service_laptop_icon});
        }
      }
      &.service-05 {
        &:before {
          background-image: url(${service_shield_icon});
        }
      }
      &.service-06 {
        &:before {
          background-image: url(${service_tools_icon});
        }
      }
      &.service-07 {
        &:before {
          background-image: url(${service_cicle_icon});
        }
      }
      &.service-08 {
        &:before {
          background-image: url(${service_bag_icon});
        }
      }
      &.service-09 {
        &:before {
          background-image: url(${service_squares_icon});
        }
      }
      &.service-10 {
        &:before {
          background-image: url(${service_sidebar_icon});
        }
      }
      &.service-11 {
        &:before {
          background-image: url(${service_int_icon});
        }
      }
      @media (max-width: 590px) {
        span {
          width: 120px;
        }
      }
    }
  }
`;

export const Apps = styled.div`
  position: relative;

  background: #000;
  padding-top: 200px;

  padding: 100px 0;

  display: flex;
  flex-direction: column;
  align-items: center;
`

export const PurpleBackground = styled.img`
  position: absolute;
  top: 0;
  left: 0;

  max-width: 1526px;
  width: 100%;
  height: auto;

  z-index: 1;
`

export const CyanBackground = styled.img`
  position: absolute;
  transform: translateY(100%);
  right: 0;

  max-width: 1526px;
  width: 100%;
  height: auto;

  z-index: 1;
`

export const LightPurpleBackground = styled.img`
  position: absolute;
  transform: translateY(260%);
  left: 0;

  max-width: 1526px;
  width: 100%;
  height: auto;

  z-index: 1;

  @media (max-width: 425px) {
    transform: translateY(280%);
  }

  @media (max-width: 375px) {
    transform: translateY(300%);
  }

  @media (max-width: 320px) {
    transform: translateY(320%);
  }
`

export const AppsContent = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  padding: 0px 20px;

  flex-wrap: wrap;

  z-index: 2;

  @media (max-width: 1024px) {
    padding: 0 20px;
  }
`

export const AppsSubtitle = styled(Description)`
  text-align: start;

  z-index: 2;
`

export const AppsTitle = styled(Title)`
  margin-top: 15px;
  color: #fff;
  text-align: start;

  z-index: 2;
`

export const AppsButton = styled(SecurityButton)`
  background-image: url(${background_rounded_white_button});

  color: #fff;

  z-index: 2;
`

export const AppsMockup = styled.img`
  position: relative;
  max-width: 1919px;
  width: 100%;
  height: auto;

  z-index: 3;
`

export const AlternativeAppsSubtitle = styled(Description)`
  align-self: flex-end;

  color: #420066;
  text-align: end;

  z-index: 2;

  margin-top: 20vw;

  @media (max-width: 680px) {
    margin-top: 6vw;
  }
`

export const AlternativeAppsTitle = styled(Title)`
  align-self: flex-end;

  margin-top: 15px;

  color: #420066;
  text-align: end;

  z-index: 2;
`

export const AlternativeAppsButton = styled(SecurityButton)`
  background-image: url(${background_rounded_purple_button});

  align-self: flex-end;

  color: #420066;

  z-index: 2;
`

export const SoftwareMockup = styled.img`
  align-self: center;

  margin-top: 5vh;

  position: relative;
  max-width: 1639px;
  width: 100%;
  height: auto;

  z-index: 3;
`

export const AppSitePremiun = styled(Description)`
  text-align: start;
  z-index: 2;
  margin-top: 50vw;

  @media (max-width: 1164px) {
    margin-top: 36vw;
  }

  @media (max-width: 880px) {
    margin-top: 30vw;
  }

  @media (max-width: 640px) {
    margin-top: 26vw;
  }

  @media (max-width: 500px) {
    margin-top: 10vw;
  }
`

export const SiteMockup = styled.img`
  align-self: center;

  transform: translateY(-15%);

  position: relative;
  max-width: 1643px;
  width: 100%;
  height: auto;

  z-index: 3;

  @media (max-width: 425px) {
    transform: translateY(0%);
  }

  @media (min-width: 700.1px) {
    margin-top: 20vh;
  }

  @media (max-width: 700px) {
    margin-top: 60px;
  }

  @media (max-width: 422px) {
    margin-top: 10px;
  }
`

export const Requirements = styled.div`
  &.requirements {position: relative;

  background: #000;
  padding-top: 2em;

  padding: 100px 0;

  display: flex;
  flex-direction: column;
  align-items: center;}
`

export const RequirementsContent = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 1280px;
  width: 100%;

  margin: 0 auto;
  padding: 0 1em;

  flex-wrap: wrap;

  z-index: 2;

  @media (max-width: 1024px) {
    padding: 0 20px;
  }
`

export const RequirementsIntro = styled.span`
  &.requirements-intro{
    padding: 1em 0;
    font-size: 30px;
    font-size: clamp(16px, 5vw, 30px);
    color: #fff;

    b{
      font-weight: bold;
      color: var(--green21);
    }

}
`;

export const RequirementsList = styled.div`
  margin-top: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 6px;
  justify-content: space-around;

  width: 100%;

  flex-wrap: wrap;
  gap: 40px;
  align-items: flex-start;

  @media (max-width: 1024px) {
    justify-content: center;
  }
`

export const TimeTitle = styled(Title)`
  margin-top: 70px;
`

export const BoxesContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;

  justify-content: space-evenly;

  flex-wrap: wrap;
  gap: 50px;

  padding: 2em 15px;

  @media (max-width: 1024px) {
    justify-content: center;
  }
`

export const LeftBox = styled.button`
  cursor: pointer;

  max-width: 574px;
  width: 90%;
  height: 260px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border: 1px solid #a500ff;
  border-radius: 52px;

  background: ${({ selected }: { selected?: boolean }) =>
    selected ? '#9900CC' : 'unset'};
`

export const RightBox = styled(LeftBox)`
  border: 1px solid #21ffb5;

  background: ${({ selected }: { selected?: boolean }) =>
    selected ? '#21ffb5' : 'unset'};
`

export const BoxTitle = styled.h4`
  font-size: 35px;
  font-size: clamp(20px, 5vw, 35px);

  font-weight: bold;
  color: #fff;
  text-align: center;
`

export const BoxTime = styled.p`
  font-size: 35px;
  font-size: clamp(20px, 5vw, 35px);

  font-weight: 300;
  color: #fff;
  text-align: center;
`

export const Prices = styled.div`
  position: relative;

  background: #000;
  padding-top: 200px;

  padding: 100px 0 0 0;

  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: #101010;
`

export const PricesContent = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 1280px;
  width: 100%;
  margin: 0 auto;

  flex-wrap: wrap;

  z-index: 2;

  @media (max-width: 1024px) {
    padding: 0 20px;
  }
`

export const PricesTitle = styled(Title)`
  color: #fff;
`

export const PricesAndPayments = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  flex-wrap: wrap;

  width: 100%;

  margin-top: 80px;
  gap: 50px;
  padding: 0px 20px;

  @media (max-width: 1080px) {
    justify-content: center;
  }
`

export const PricesCards = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  max-width: 1280px;
  width: 100%;

  height: 100%;
  min-height: 280px;

  gap: 50px;

  overflow: hidden;

  flex-wrap: wrap;
`

export const PriceCard = styled.button`
  cursor: pointer;

  font-family: 'Montserrat';

  max-width: 503px;
  width: 90%;
  height: 180px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  border: unset;
  border-radius: 197px;

  background: ${({ selected }: { selected?: boolean }) =>
    selected ? '#9900CC' : 'unset'};

  border: ${({ selected }: { selected?: boolean }) =>
    selected ? 'unset' : '1px solid #9900CC'};
`

export const PriceCardTitle = styled.h4`
  font-size: 35px;
  font-size: clamp(20px, 5vw, 35px);

  font-weight: bold;
  color: #fff;
  text-align: center;
`

export const PriceCardValue = styled.p`
  font-size: 35px;
  font-size: clamp(20px, 5vw, 35px);

  font-weight: 300;
  color: #fff;
  text-align: center;
`

export const AlternativePriceCard = styled(PriceCard)`
  position: relative;

  background: ${({ selected }: { selected?: boolean }) =>
    selected ? '#21ffb5' : 'unset'};

  border: ${({ selected }: { selected?: boolean }) =>
    selected ? 'unset' : '1px solid #21ffb5'};
`

export const AlternativePriceCardTitle = styled.h4`
  font-size: 35px;
  font-size: clamp(20px, 5vw, 35px);

  font-weight: bold;
  color: ${({ selected }: { selected?: boolean }) =>
    selected ? '#000' : '#21ffb5'};
  text-align: center;
`

export const AlternativePriceCardValue = styled.p`
  font-size: 35px;
  font-size: clamp(10px, 5vw, 35px);

  font-weight: 300;
  color: ${({ selected }: { selected?: boolean }) =>
    selected ? '#000' : '#21ffb5'};
  text-align: center;
`

export const AlternativePriceCardBubble = styled.div`
  position: absolute;
  top: -50px;
  right: 0px;

  max-width: 116px;
  max-height: 116px;
  width: 100%;
  height: 100%;

  border: unset;
  border-radius: 60px;

  background: ${({ selected }: { selected?: boolean }) =>
    selected ? '#9900cc' : '#21ffb5'};

  display: flex;
  align-items: center;
  justify-content: center;

  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
`

export const AlternativePriceCardBubbleText = styled.p`
  font-size: 35px;
  font-size: clamp(20px, 5vw, 35px);

  font-weight: 600;
  color: ${({ selected }: { selected?: boolean }) =>
    selected ? '#fff' : '#101010'};
  text-align: center;
`

export const ContractDetails = styled.div`
  width: 100%;
  background: ${({ alt }: { alt?: boolean }) => (alt ? '#21ffb5' : '#9900cc')};

  padding: 80px 0;

  @media (max-width: 1080px) {
    margin-top: 20px;
  }
`

export const ContractContent = styled.div`
  margin: 0 auto;
  max-width: 1280px;
  width: 100%;

  display: flex;
  flex-direction: column;

  gap: 61px;

  @media (max-width: 1080px) {
    padding: 0 20px;
  }
`

export const ContractTitle = styled.h2`
  font-size: 60px;
  font-size: clamp(50px, 5vw, 60px);

  font-weight: bold;
  color: ${({ alt }: { alt?: boolean }) => (alt ? '#000000' : '#fff')};
  text-align: center;

  @media (max-width: 1080px) {
    text-align: center;
    margin: 0 auto;
  }
`

export const ContractItemRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  gap: 13px;
`

export const ContractItemIcon = styled.img`
  max-width: 28px;
  width: 100%;
  height: auto;
`

export const ContractItem = styled.p`
  font-size: 20px;
  font-size: clamp(15px, 5vw, 20px);

  font-weight: bold;
  color: ${({ alt }: { alt?: boolean }) => (alt ? '#000000' : '#fff')};
  text-align: left;
`

export const PaymentMethods = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  width: 100%;

  flex-wrap: wrap;

  @media (max-width: 1080px) {
    gap: 30px;

    margin-top: 20px;
  }
`

export const PaymentMethod = styled.div`
  max-width: 736px;
  width: 100%;
  border: unset;

  padding: 60px 0 46px 0;

  border-bottom-left-radius: 94px;
  border-bottom-right-radius: 94px;

  background-color: ${({ alt }: { alt?: boolean }) =>
    alt ? '#101010' : '#2d2e2e'};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 14px;

  @media (max-width: 1080px) {
    padding: 20px 0 15px 0;

    border-radius: 94px;
  }
`

export const PaymentMethodText = styled.p`
  font-size: 35px;
  font-size: clamp(20px, 5vw, 35px);

  font-weight: 300;
  color: #fff;
  text-align: center;

  > b {
    font-weight: bold;
  }

  @media (max-width: 1080px) {
    text-align: center;
    margin: 0 auto;
  }
`

export const PaymentMethodTitle = styled.h3`
  font-size: 35px;
  font-size: clamp(20px, 5vw, 35px);

  font-weight: bold;
  color: #21ffb5;
  text-align: right;

  @media (max-width: 1080px) {
    text-align: center;
    margin: 0 auto;
  }
`

export const TechnicalSupport = styled.div`
  position: relative;

  background: #000;
  padding-top: 200px;

  padding: 100px 0;

  display: flex;
  flex-direction: column;
  align-items: center;

  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  background-image: url(${background});
`

export const TechnicalSupportContent = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 1280px;
  width: 100%;
  margin: 0 auto;

  flex-wrap: wrap;

  z-index: 2;

  @media (max-width: 1024px) {
    padding: 0 20px;
  }
`

export const TechnicalSupportDescription = styled.p`
  font-size: 15px;
  font-size: clamp(10px, 5vw, 15px);

  width: 40%;

  margin-top: 15px;

  font-weight: 300;
  color: #fff;
  text-align: center;

  > b {
    font-weight: bold;
    color: #21ffb5;
  }
`

export const TechnicalSupportItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  flex-wrap: wrap;

  width: 100%;

  margin-top: 80px;
  gap: 50px;

  @media (max-width: 1024px) {
    justify-content: center;
  }
`

export const TechnicalSupportItems = styled.div`
  display: flex;
  flex-direction: column;

  max-width: 544px;
  width: 100%;
  gap: 40px;
`

export const TechnicalSupportItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const TechnicalSupportIcon = styled.img`
  max-width: 16px;
  height: auto;
`

export const TechnicalSupportItemText = styled.p`
  font-size: 25px;
  font-size: clamp(20px, 5vw, 25px);

  margin-left: 15px;

  font-weight: 300;
  color: #fff;
  text-align: left;
`

export const TechnicalSupportPrice = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 75px;

  border: 1px solid #a500ff;
  border-radius: 25px;

  margin-top: 70px;

  font-size: 25px;
  font-size: clamp(20px, 5vw, 25px);

  font-weight: 300;
  color: #fff;
  text-align: left;

  > b {
    font-weight: bold;
    color: #21ffb5;
  }
`

export const ContactWrapper = styled.div`
  &.contact-section{
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-wrap: wrap;
      gap: 15px;

      width: 100%;
      max-width: 1200px;


      .contact__video-wrapper{
        min-width: 300px;
        width: 100%;
        max-width: 600px;
        height: 100%;
        height: 450px;

        padding: 5% 0;
        overflow: hidden;
        border-radius: 15px;

        iframe{
          pointer-events: all;
        }
      }
      
  }
`;

export const Contact = styled.div`
  margin-top: 3em;

  max-width: 607px;
  width: 90%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const ContactText = styled.p`
  font-size: 25px;
  font-size: clamp(20px, 5vw, 25px);

  margin-top: 20px;

  font-weight: 300;
  color: #fff;
  text-align: center;
`

export const ContactTitle = styled.h1`
  font-size: 55px;
  font-size: clamp(40px, 5vw, 55px);

  margin-top: 20px;

  font-weight: bold;
  color: #fff;
  text-align: center;
`

export const ContactButton = styled(SecurityButton)`
  max-width: 400px;
  height: 100px;
  width: 100%;

  @media (max-width: 400px) {
    font-size: 18px;
  }
`


