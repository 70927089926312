import React, { useState } from 'react';


import { RequirementContainer, RequirementDescription, RequirementNumber, RequirementTitle, RequirementTitleContainer } from './_requirementComponent';

interface Props {
    requisitos: {
        titulo: string;
        descricao: string
    }
    index: number
}
export const RequirementComponent: React.FC<Props> = ({requisitos, index}) => {
    const [ showDescription, setShowDescription] = useState<boolean>(false)

    const renderDescriptionPoints = (description: string) => {
        const points = description.split('\r\n\r\n')

        const pointsWithBulltet = points.map((item) => {
            return <li className='requirements__item'>{item}</li>
        })

        return <ul className='requirements__item-list'>
            {pointsWithBulltet}
        </ul>
    }
    
    return (
        <RequirementContainer className='requirements__container'>
        <RequirementTitleContainer className='requirements__title-container'>
          <RequirementNumber>{index < 9  ? `0${index + 1}` : index + 1}</RequirementNumber>
          <RequirementTitle
          onClick={() => setShowDescription(!showDescription)}
          >{requisitos.titulo.toUpperCase()} 
          <small><span>{showDescription ? '-' : '+'}</span> detalhes</small>
          </RequirementTitle>
        </RequirementTitleContainer>
        <RequirementDescription className={`requirements__description  description-${showDescription}`}>
          {renderDescriptionPoints(requisitos.descricao)}
        </RequirementDescription>
      </RequirementContainer>
    );
};